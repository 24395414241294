import React from "react";
import { Helmet } from "react-helmet";
import "../scss/main.scss";

function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>KingSwap | Check Back Soon</title>
        <link rel="canonical" href="https://www.kingswap.com/" />
      </Helmet>
      <div className="landing">
        <div className="landing__context">
          <a className="landing__logo" href="https://www.kingswap.com/"></a>
          <h1 className="landing__heading">Buy, Sell & Swap Tickets</h1>
          <h2 className="landing__sub">Check Back Soon</h2>
        </div>
      </div>
    </>
  );
}

export default App;
